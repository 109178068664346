body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  color: #333;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 2em;
  height: 100vh;
}

.contacts {
  margin-bottom: 2em;
  min-width: 200px;
}

.app-list {
  display: flex;
  flex-direction: row;
  gap: 1em;
  background: #fff;
  border-radius: 5px;
  flex-wrap: wrap;
}

.app-list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
  width: 200px;
  min-width: 160px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.view-policy-button {
  background: linear-gradient(to right, #ff416c, #ff4b2b);
  color: #fff;
  border-radius: 5px;
  padding: 1em;
  cursor: pointer;
  text-decoration: none;
  margin-top: 1em;
}

.view-policy-button:hover {
  background: linear-gradient(to right, #ff4b2b, #ff416c);
}

.privacy-policy {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
}

.remove-account {
  margin-top: 2em;
}

.remove-account a:link,
a:visited {
  text-decoration: none;
  color: #333;
}

.remove-account a:hover {
  text-decoration: underline;
}
